/* global */
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
class Home
{
    constructor () 
    {
        this.initBannerSlider();
        console.log('Home initialised');
    }

    initBannerSlider() {
        new Swiper(".banner-slider", {
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            speed: 1000,
        });
    }
}

new Home;